import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { AuthContext } from '../AuthContext';
import { supabase } from '../supabaseClient';
import OrgadoroWideLogoForDark from '../Orgadoro_WideLogo_ForDark.png';


function AppNavbar() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
        <img
            src={OrgadoroWideLogoForDark}
            alt="Orgadoro"
            height="30"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {user ? (
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/project-groups">
                <i className="fas fa-layer-group me-2"></i>Project Groups
              </Nav.Link>
              <Nav.Link as={Link} to="/projects">
                <i className="fas fa-project-diagram me-2"></i>Projects
              </Nav.Link>
              <Nav.Link as={Link} to="/tasks">
                <i className="fas fa-tasks me-2"></i>Tasks
              </Nav.Link>
            </Nav>
          ) : null}
          <Nav className="ms-auto">
            {user ? (
              <NavDropdown
                align="end"
                title={
                  <>
                    <i className="fas fa-user-circle me-2"></i> {user.email}
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/profile">
                  <i className="fas fa-user me-2"></i>Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/preferences">
                  <i className="fas fa-cog me-2"></i>Preferences
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleSignOut}>
                  <i className="fas fa-sign-out-alt me-2"></i>Signout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav>
                <Nav.Link as={Link} to="/login">
                  <i className="fas fa-sign-in-alt me-2"></i>Login
                </Nav.Link>
                <Nav.Link as={Link} to="/sign-up">
                  <i className="fas fa-user-plus me-2"></i>Sign Up
                </Nav.Link>
              </Nav>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppNavbar;