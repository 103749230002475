import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useToast } from '../ToastContext';

function EditProjectGroupModal({ show, handleClose, item, onItemUpdated, onItemDeleted }) {
  const [name, setName] = useState(item ? item.name : '');
  const [description, setDescription] = useState(item ? item.description : '');
  const addToast = useToast();

  const handleSave = async () => {
    const { id, type } = item;
    const table = 'project_groups';
    const { error } = await supabase
      .from(table)
      .update({ name, description })
      .eq('id', id)
      .select();

    if (error) {
      console.error('Error updating item:', error);
      addToast('Error updating item: ' + error.message, 'danger');
    } else {
      addToast('Item updated successfully', 'success');
      console.log('Item updated:', { id, name, description });
      onItemUpdated({ ...item, name, description });
      handleClose();
    }
  };

  const handleDelete = async () => {
    const { id, type } = item;
    const table = type === 'project_group' ? 'project_groups' : type === 'project' ? 'projects' : 'tasks';
    const { error } = await supabase
      .from(table)
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting item:', error);
      addToast('Error deleting item: ' + error.message, 'danger');
    } else {
      addToast('Item deleted successfully', 'success');
      onItemDeleted(id);
      handleClose();
    }
  };

  const handleNewFunction = () => {
    // Add your new function here
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditProjectGroupModal;