import React from 'react';

function Tasks() {
  return (
    <div className="container mt-5">
      <h1>Tasks</h1>
      <p>Manage your tasks here.</p>
    </div>
  );
}

export default Tasks;