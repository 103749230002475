import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';

function Preferences() {
  const [theme, setTheme] = useState('auto');

  useEffect(() => {
    const savedTheme = Cookies.get('theme') || 'auto';
    setTheme(savedTheme);
    applyTheme(savedTheme);
  }, []);

  const handleThemeChange = (e) => {
    const selectedTheme = e.target.value;
    setTheme(selectedTheme);
    Cookies.set('theme', selectedTheme, { expires: 365 });
    applyTheme(selectedTheme);
  };

  const applyTheme = (theme) => {
    document.body.classList.remove('dark-mode', 'light-mode', 'bg-dark', 'text-white', 'bg-light', 'text-dark');
    if (theme === 'dark') {
      document.body.classList.add('dark-mode', 'bg-dark', 'text-white');
    } else if (theme === 'light') {
      document.body.classList.add('light-mode', 'bg-light', 'text-dark');
    }
  };

  return (
    <Container className="mt-5">
      <h1>Preferences</h1>
      <Form>
        <Form.Group controlId="themeSelect">
          <Form.Label>Select Theme</Form.Label>
          <Form.Control as="select" value={theme} onChange={handleThemeChange}>
            <option value="auto">Auto</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </Form.Control>
        </Form.Group>
        <Button variant="primary" className="mt-3" onClick={() => alert('Preferences saved!')}>
          Save Preferences
        </Button>
      </Form>
    </Container>
  );
}

export default Preferences;