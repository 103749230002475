import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useToast } from '../ToastContext';

function DeleteProjectGroupModal({ show, handleClose, item, onItemUpdated, onItemDeleted }) {
  const [name, setName] = useState(item ? item.name : '');
  const [description, setDescription] = useState(item ? item.description : '');
  const addToast = useToast();

  const handleDelete = async () => {
    const { id, type } = item;
    const table = 'project_groups';
    const { error } = await supabase
      .from(table)
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting item:', error);
      addToast('Error deleting item: ' + error.message, 'danger');
    } else {
      addToast('Item deleted successfully', 'success');
      console.log('Item deleted:', id);
      onItemDeleted(id);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to permanently delete this item: "{name}"?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteProjectGroupModal;