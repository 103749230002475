import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ProjectGroups from './pages/ProjectGroups';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import ProjectGroupDetail from './pages/ProjectGroupDetail';
import Tasks from './pages/Tasks';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import Profile from './pages/Profile';
import Preferences from './pages/Preferences';
import PrivateRoute from './PrivateRoute';
import { ToastProvider } from './ToastContext';
import './App.css';

function App() {
  return (
    <ToastProvider>
      <div className="App">
        <Router>
          <header className="fixed-top">
            <Navbar />
          </header>
          <main className="App-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/project-groups" element={<ProjectGroups />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/project/:id" element={<ProjectDetail />} />
              <Route path="/project-group/:id" element={<ProjectGroupDetail />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
              <Route path="/preferences" element={<Preferences />} />
            </Routes>
          </main>
          <footer className="fixed-bottom">
            <Footer />
          </footer>
        </Router>
      </div>
    </ToastProvider>
  );
}

export default App;