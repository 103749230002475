import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { FaPlus, FaStar, FaRegStar } from 'react-icons/fa';
import { useToast } from '../ToastContext';
import AddProjectModal from '../components/AddProjectModal';
import ProjectCard from '../components/ProjectCard';
import { AuthContext } from '../AuthContext';
import { Link, useParams } from 'react-router-dom';

function ProjectGroupDetail() {
  const { id } = useParams();
  const [projectGroup, setProjectGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const { user } = useContext(AuthContext);
  const addToast = useToast();

  useEffect(() => {
    const fetchProjectGroup = async () => {
      const { data, error } = await supabase
        .from('project_groups')
        .select('id, name, description, is_favorite, projects (id, name, description, project_group (id, name), tasks (id, name, description), is_favorite, tags, color)')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching project group:', error);
        addToast('Error fetching project group: ' + error.message, 'danger');
      } else {
        setProjectGroup(data);
      }
      setLoading(false);
    };

    fetchProjectGroup();
  }, [id, addToast]);

  const handleProjectUpdated = (updatedProject) => {
    setProjectGroup((prev) => ({
      ...prev,
      projects: prev.projects.map((project) =>
        project.id === updatedProject.id ? updatedProject : project
      ),
    }));
  };

  const handleProjectDeleted = (deletedProjectId) => {
    setProjectGroup((prev) => ({
      ...prev,
      projects: prev.projects.filter((project) => project.id !== deletedProjectId),
    }));
  };

  const handleProjectAdded = (newProject) => {
    setProjectGroup((prev) => ({
      ...prev,
      projects: [...prev.projects, newProject],
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!projectGroup) {
    return <div>Project group not found</div>;
  }

  return (
    <Container className="mt-5">
      <h1>
        {projectGroup.name}
        {projectGroup.is_favorite ? <FaStar color="gold" className="ms-2" /> : <FaRegStar className="ms-2" />}
      </h1>
      <p>{projectGroup.description}</p>



      <Button variant="primary" onClick={() => setShowAddProjectModal(true)}>
        <FaPlus /> Add Project
      </Button>
      <Row className="mt-3">
        {projectGroup.projects.map((project) => (
          <Col key={project.id} sm={12} md={6} lg={4} className="mb-3">
            <ProjectCard
              project={project}
              onProjectUpdated={handleProjectUpdated}
              onProjectDeleted={handleProjectDeleted}
            />
          </Col>
        ))}
      </Row>
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          handleClose={() => setShowAddProjectModal(false)}
          projectGroupId={projectGroup.id}
          onProjectAdded={handleProjectAdded}
        />
      )}
    </Container>
  );
}

export default ProjectGroupDetail;