import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useToast } from '../ToastContext';

function AddProjectModal({ show, handleClose, onProjectAdded }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [projectGroups, setProjectGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const addToast = useToast();

  useEffect(() => {
    const fetchProjectGroups = async () => {
      const { data, error } = await supabase
        .from('project_groups')
        .select('id, name');

      if (error) {
        console.error('Error fetching project groups:', error);
        addToast('Error fetching project groups: ' + error.message, 'danger');
      } else {
        setProjectGroups(data);
      }
    };

    fetchProjectGroups();
  }, [addToast]);

  const handleSave = async () => {
    const { data, error } = await supabase
      .from('projects')
      .insert([{ name, description, project_group: selectedGroupId }])
      .select('id, name, description, project_group (id, name), tasks (id)');

    if (error) {
      console.error('Error adding project:', error);
      addToast('Error adding project: ' + error.message, 'danger');
    } else if (data && data.length > 0) {
      addToast('Project added successfully', 'success');
      onProjectAdded(data[0]);
      handleClose();
    } else {
      console.error('Unexpected response format:', data);
      addToast('Unexpected response format when adding project', 'danger');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="projectGroup" className="mt-3">
            <Form.Label>Project Group</Form.Label>
            <Form.Control
              as="select"
              value={selectedGroupId}
              onChange={(e) => setSelectedGroupId(e.target.value)}
            >
              <option value="">Select a project group</option>
              {projectGroups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddProjectModal;