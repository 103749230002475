import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useToast } from '../ToastContext';

function DeleteProjectModal({ show, handleClose, project, onProjectDeleted }) {
  const addToast = useToast();

  const handleDelete = async () => {
    const { error } = await supabase
      .from('projects')
      .delete()
      .eq('id', project.id);

    if (error) {
      console.error('Error deleting project:', error);
      addToast('Error deleting project: ' + error.message, 'danger');
    } else {
      addToast('Project deleted successfully', 'success');
      onProjectDeleted(project.id);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the project "{project.name}"?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteProjectModal;