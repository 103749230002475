import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

function Profile() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const user = supabase.auth.user();
      if (user) {
        const { data, error } = await supabase.from('users').select('*').eq('id', user.id).single();
        if (error) setError(error.message);
        else setProfile(data);
      }
    };
    fetchProfile();
  }, []);

  return (
    <div className="container mt-5">
      <h1>My Profile</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      {profile ? (
        <div>
          <p><strong>Email:</strong> {profile.email}</p>
          <p><strong>Username:</strong> {profile.id}</p>
          {/* Add more profile fields as needed */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Profile;