import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

function ChangePassword() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const { error } = await supabase.auth.updateUser({ password });
    if (error) setError(error.message);
    else alert('Password updated successfully.');
  };

  return (
    <div className="container mt-5">
      <h1>Change Password</h1>
      <form onSubmit={handleChangePassword}>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">New Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button type="submit" className="btn btn-primary">Change Password</button>
      </form>
    </div>
  );
}

export default ChangePassword;