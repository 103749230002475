import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Card, Row, Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { useToast } from '../ToastContext';
import AddProjectGroupModal from '../components/AddProjectGroupModal';
import EditProjectGroupModal from '../components/EditProjectGroupModal';
import DeleteProjectGroupModal from '../components/DeleteProjectGroupModal';
import AddProjectModal from '../components/AddProjectModal';
import { AuthContext } from '../AuthContext';

function Home() {
  const [projectGroups, setProjectGroups] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { user } = useContext(AuthContext);
  const addToast = useToast();

  useEffect(() => {
    const fetchProjectGroups = async () => {
      const { data, error } = await supabase
        .from('project_groups')
        .select('id, name, description, projects (id)');

      if (error) {
        console.error('Error fetching project groups:', error);
        addToast('Error fetching project groups: ' + error.message, 'danger');
      } else {
        setProjectGroups(data);
      }
    };

    fetchProjectGroups();
  }, [addToast]);

  const handleAddGroup = () => {
    setShowAddModal(true);
  };

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setShowEditModal(true);
  };

  const handleDeleteGroup = (group) => {
    setSelectedGroup(group);
    setShowDeleteModal(true);
  };

  const handleAddProject = (groupId) => {
    setSelectedGroupId(groupId);
    setShowAddProjectModal(true);
  };

  return (
    <Container className="mt-5">
      <h1>Project Groups</h1>
      <Button variant="primary" onClick={handleAddGroup}>
        <FaPlus /> Add Project Group
      </Button>
      <Row className="mt-3">
        {projectGroups.map((group) => (
          <Col key={group.id} sm={12} md={6} lg={4} className="mb-3">
            <Card className="project-group-card">
              <Card.Body>
                <Card.Title>
                  {group.name}
                  <Badge pill bg="info" className="float-end">
                    {group.projects.length}
                  </Badge>
                </Card.Title>
                <Card.Text>{group.description}</Card.Text>
                <div className="d-flex justify-content-end">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-add-${group.id}`}>Add Project</Tooltip>}
                  >
                    <Button variant="link" onClick={() => handleAddProject(group.id)}>
                      <FaPlus />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-edit-${group.id}`}>Edit Project Group</Tooltip>}
                  >
                    <Button variant="link" onClick={() => handleEditGroup(group)}>
                      <FaEdit />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`tooltip-delete-${group.id}`}>Delete Project Group</Tooltip>}
                  >
                    <Button variant="link" onClick={() => handleDeleteGroup(group)}>
                      <FaTrash />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {showAddModal && (
        <AddProjectGroupModal
          show={showAddModal}
          handleClose={() => setShowAddModal(false)}
          userId={user.id}
          onProjectGroupAdded={(newGroup) => setProjectGroups([...projectGroups, newGroup])}
        />
      )}
      {showEditModal && (
        <EditProjectGroupModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          item={selectedGroup}
          onItemUpdated={(updatedGroup) =>
            setProjectGroups((prev) =>
              prev.map((group) => (group.id === updatedGroup.id ? updatedGroup : group))
            )
          }
        />
      )}
      {showDeleteModal && (
        <DeleteProjectGroupModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          item={selectedGroup}
          onItemDeleted={(deletedGroupId) =>
            setProjectGroups((prev) => prev.filter((group) => group.id !== deletedGroupId))
          }
        />
      )}
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          handleClose={() => setShowAddProjectModal(false)}
          projectGroupId={selectedGroupId}
          onProjectAdded={(newProject) =>
            setProjectGroups((prev) =>
              prev.map((group) =>
                group.id === selectedGroupId
                  ? { ...group, projects: [...group.projects, newProject] }
                  : group
              )
            )
          }
        />
      )}
    </Container>
  );
}

export default Home;