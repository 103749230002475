import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Card, Row, Col, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { FaEdit, FaTrash, FaPlus, FaStar, FaRegStar } from 'react-icons/fa';
import { useToast } from '../ToastContext';
import AddProjectGroupModal from '../components/AddProjectGroupModal';
import EditProjectGroupModal from '../components/EditProjectGroupModal';
import DeleteProjectGroupModal from '../components/DeleteProjectGroupModal';
import AddProjectModal from '../components/AddProjectModal';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';

function ProjectGroups() {
  const [projectGroups, setProjectGroups] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { user } = useContext(AuthContext);
  const addToast = useToast();

  useEffect(() => {
    const fetchProjectGroups = async () => {
      const { data, error } = await supabase
        .from('project_groups')
        .select('id, name, description, is_favorite, projects (id)');

      if (error) {
        console.error('Error fetching project groups:', error);
        addToast('Error fetching project groups: ' + error.message, 'danger');
      } else {
        // Sort project groups: favorited first, then alphabetical
        const sortedData = data.sort((a, b) => {
          if (a.is_favorite === b.is_favorite) {
            return a.name.localeCompare(b.name);
          }
          return b.is_favorite - a.is_favorite;
        });
        setProjectGroups(sortedData);
      }
    };

    fetchProjectGroups();
  }, [addToast]);

  const handleAddProjectGroup = () => {
    setShowAddModal(true);
  };

  const handleEditProjectGroup = (group) => {
    setSelectedGroup(group);
    setShowEditModal(true);
  };

  const handleDeleteProjectGroup = (group) => {
    setSelectedGroup(group);
    setShowDeleteModal(true);
  };

  const handleAddProject = (groupId) => {
    setSelectedGroupId(groupId);
    setShowAddProjectModal(true);
  };

  const toggleFavorite = async (groupId, isFavorite) => {
    const { data, error } = await supabase
      .from('project_groups')
      .update({ is_favorite: !isFavorite })
      .eq('id', groupId)
      .single();

    if (error) {
      console.error('Error updating favorite status:', error);
      addToast('Error updating favorite status: ' + error.message, 'danger');
    } else {
      setProjectGroups((prev) =>
        prev
          .map((group) => (group.id === groupId ? { ...group, is_favorite: !isFavorite } : group))
          .sort((a, b) => {
            if (a.is_favorite === b.is_favorite) {
              return a.name.localeCompare(b.name);
            }
            return b.is_favorite - a.is_favorite;
          })
      );
      addToast('Favorite status updated successfully', 'success');
    }
  };

  return (
    <Container className="mt-5">
      <h1>Project Groups</h1>

      <Row className="mt-3">
        {projectGroups.map((group) => (
          <Col key={group.id} sm={12} md={6} lg={4} className="mb-3">
            <Card className="project-group-card">
              <Card.Body>
                <Card.Title>
                  <Link to={`/project-group/${group.id}`} className="text-decoration-none">
                    {group.name}
                  </Link>
                  <Badge pill bg="info" className="float-end">
                    <Link to={`/project-group/${group.id}`} className="text-decoration-none text-white">
                      {group.projects.length}
                    </Link>
                  </Badge>
                  <Button variant="link" onClick={() => toggleFavorite(group.id, group.is_favorite)}>
                    {group.is_favorite ? <FaStar color="gold" /> : <FaRegStar />}
                  </Button>
                </Card.Title>
                <Card.Text>{group.description}</Card.Text>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-add-${group.id}`}>Add Project</Tooltip>}
                    >
                      <Button variant="link" onClick={() => handleAddProject(group.id)}>
                        <FaPlus />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-edit-${group.id}`}>Edit Project Group</Tooltip>}
                    >
                      <Button variant="link" onClick={() => handleEditProjectGroup(group)}>
                        <FaEdit />
                      </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip-delete-${group.id}`}>Delete Project Group</Tooltip>}
                    >
                      <Button variant="link" onClick={() => handleDeleteProjectGroup(group)}>
                        <FaTrash />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Button variant="primary" onClick={handleAddProjectGroup}>
        <FaPlus /> Add Project Group
      </Button>

      {showAddModal && (
        <AddProjectGroupModal
          show={showAddModal}
          handleClose={() => setShowAddModal(false)}
          userId={user.id}
          onProjectGroupAdded={(newGroup) => setProjectGroups([...projectGroups, newGroup])}
        />
      )}
      {showEditModal && (
        <EditProjectGroupModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          item={selectedGroup}
          onItemUpdated={(updatedGroup) =>
            setProjectGroups((prev) =>
              prev.map((group) => (group.id === updatedGroup.id ? updatedGroup : group))
            )
          }
        />
      )}
      {showDeleteModal && (
        <DeleteProjectGroupModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          item={selectedGroup}
          onItemDeleted={(deletedGroupId) =>
            setProjectGroups((prev) => prev.filter((group) => group.id !== deletedGroupId))
          }
        />
      )}
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          handleClose={() => setShowAddProjectModal(false)}
          projectGroupId={selectedGroupId}
          onProjectAdded={(newProject) =>
            setProjectGroups((prev) =>
              prev.map((group) =>
                group.id === selectedGroupId
                  ? { ...group, projects: [...group.projects, newProject] }
                  : group
              )
            )
          }
        />
      )}
    </Container>
  );
}

export default ProjectGroups;