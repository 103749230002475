import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useToast } from '../ToastContext';

function ProjectDetail() {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const addToast = useToast();

  useEffect(() => {
    const fetchProject = async () => {
      const { data, error } = await supabase
        .from('projects')
        .select('id, name, description, project_group (id, name)')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching project:', error);
        addToast('Error fetching project: ' + error.message, 'danger');
      } else {
        setProject(data);
      }
    };

    const fetchTopLevelTasks = async () => {
      const { data, error } = await supabase
        .from('tasks')
        .select('id, name, description, parent_task')
        .eq('project', id);

      if (error) {
        console.error('Error fetching top-level tasks:', error);
        addToast('Error fetching top-level tasks: ' + error.message, 'danger');
      } else {
        setTasks(data);
      }
      setLoading(false);
    };

    fetchProject();
    fetchTopLevelTasks();
  }, [id, addToast]);

  const fetchChildTasks = async (parentId) => {
    const { data, error } = await supabase
      .from('tasks')
      .select('id, name, description, parent_task')
      .eq('parent_task', parentId);

    if (error) {
      console.error('Error fetching child tasks:', error);
      addToast('Error fetching child tasks: ' + error.message, 'danger');
      return [];
    } else {
      return data;
    }
  };

  const renderTasks = (tasks) => {
    return tasks.map(task => (
      <li key={task.id}>
        <strong>{task.name}</strong>: {task.description}
        <ul>
          {task.childTasks && renderTasks(task.childTasks)}
        </ul>
      </li>
    ));
  };

  const loadChildTasks = async (task) => {
    const childTasks = await fetchChildTasks(task.id);
    task.childTasks = childTasks;
    for (const childTask of childTasks) {
      await loadChildTasks(childTask);
    }
    setTasks([...tasks]);
  };

  useEffect(() => {
    tasks.forEach(task => {
      loadChildTasks(task);
    });
  }, [tasks]);

  return (
    <Container className="mt-5">
      {loading ? (
        <p>Loading...</p>
      ) : (
        project && (
          <>
            <h1>{project.name}</h1>
            <p>{project.description}</p>
            <p>
              Project Group: <Link to={`/project-group/${project.project_group.id}`}>{project.project_group.name}</Link>
            </p>
            <h2>Tasks</h2>
            <ul>{renderTasks(tasks)}</ul>
          </>
        )
      )}
    </Container>
  );
}

export default ProjectDetail;