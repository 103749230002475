import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, Container, Form, Button, Alert } from 'react-bootstrap';
import { supabase } from '../supabaseClient';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const { error, data } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      setError(error.message);
    } else {
      localStorage.setItem('user', JSON.stringify(data.user));
      navigate('/');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Card style={{ width: '100%', maxWidth: '400px' }}>
        <Card.Body>
          <h1 className="text-center mb-4">Login</h1>
          <p className="text-center mb-4">
            Please enter your email and password to log in. If you don't have an account, you can sign up.
          </p>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            {error && <Alert variant="danger">{error}</Alert>}
            <Button type="submit" className="w-100 mb-3">
              <i className="fas fa-sign-in-alt me-2"></i>Login
            </Button>
          </Form>
          <div className="d-flex justify-content-between">
            <Link to="/forgot-password" className="text-decoration-none">
              <i className="fas fa-unlock-alt me-2"></i>Forgot password?
            </Link>
            <Link to="/sign-up" className="text-decoration-none">
              <i className="fas fa-user-plus me-2"></i>Sign Up
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Login;