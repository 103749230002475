import React, { useState } from 'react';
import { Card, Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaTrash, FaPlus, FaStar, FaRegStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AddTaskModal from './AddTaskModal';
import EditProjectModal from './EditProjectModal';
import DeleteProjectModal from './DeleteProjectModal';
import { useToast } from '../ToastContext';
import { supabase } from '../supabaseClient';

function ProjectCard({ project, onProjectUpdated, onProjectDeleted, onToggleFavorite }) {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const addToast = useToast();

  const handleAddTask = async (task) => {
    const { data, error } = await supabase
      .from('tasks')
      .insert([{ ...task, project_id: project.id }])
      .single();

    if (error) {
      console.error('Error adding task:', error);
      addToast('Error adding task: ' + error.message, 'danger');
    } else {
      addToast('Task added successfully', 'success');
      onProjectUpdated({ ...project, tasks: [...project.tasks, data] });
    }
  };

  const handleEditProject = async (updatedProject) => {
    const { data, error } = await supabase
      .from('projects')
      .update(updatedProject)
      .eq('id', project.id)
      .single();

    if (error) {
      console.error('Error updating project:', error);
      addToast('Error updating project: ' + error.message, 'danger');
    } else {
      addToast('Project updated successfully', 'success');
      onProjectUpdated(data);
    }
  };

  const handleDeleteProject = async () => {
    const { error } = await supabase
      .from('projects')
      .delete()
      .eq('id', project.id);

    if (error) {
      console.error('Error deleting project:', error);
      addToast('Error deleting project: ' + error.message, 'danger');
    } else {
      addToast('Project deleted successfully', 'success');
      onProjectDeleted(project.id);
    }
  };

  const handleToggleFavorite = () => {
    onToggleFavorite(project.id, project.is_favorite);
  };

  return (
    <>
      <Card className="project-card" style={{ borderColor: project.color }}>
        <Card.Body>
          <Card.Title>
            <Link to={`/project/${project.id}`} className="text-decoration-none">
              {project.name}
            </Link>
            <Badge pill bg="info" className="float-end">
              <Link to={`/project/${project.id}`} className="text-decoration-none text-white">
                {project.tasks.length}
              </Link>
            </Badge>
            <Button variant="link" onClick={handleToggleFavorite}>
              {project.is_favorite ? <FaStar color="gold" /> : <FaRegStar />}
            </Button>
          </Card.Title>
          <Card.Text>{project.description}</Card.Text>
          <div className="d-flex justify-content-between align-items-center">
            <Link to={`/project-group/${project.project_group.id}`}>
              <Badge pill bg="secondary">
                {project.project_group.name}
              </Badge>
            </Link>
            <div className="d-flex">
              {project.tags && project.tags.split(',').map((tag, index) => (
                <Badge key={index} pill bg="primary" className="me-1">
                  {tag.trim()}
                </Badge>
              ))}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-add-${project.id}`}>Add Task</Tooltip>}
              >
                <Button variant="link" onClick={() => setShowAddTaskModal(true)}>
                  <FaPlus />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-edit-${project.id}`}>Edit Project</Tooltip>}
              >
                <Button variant="link" onClick={() => setShowEditProjectModal(true)}>
                  <FaEdit />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-delete-${project.id}`}>Delete Project</Tooltip>}
              >
                <Button variant="link" onClick={() => setShowDeleteProjectModal(true)}>
                  <FaTrash />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        </Card.Body>
      </Card>

      {showAddTaskModal && (
        <AddTaskModal
          show={showAddTaskModal}
          handleClose={() => setShowAddTaskModal(false)}
          projectId={project.id}
          onTaskAdded={handleAddTask}
        />
      )}
      {showEditProjectModal && (
        <EditProjectModal
          show={showEditProjectModal}
          handleClose={() => setShowEditProjectModal(false)}
          project={project}
          onProjectUpdated={handleEditProject}
        />
      )}
      {showDeleteProjectModal && (
        <DeleteProjectModal
          show={showDeleteProjectModal}
          handleClose={() => setShowDeleteProjectModal(false)}
          project={project}
          onProjectDeleted={handleDeleteProject}
        />
      )}
    </>
  );
}

export default ProjectCard;