import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { FaPlus } from 'react-icons/fa';
import { useToast } from '../ToastContext';
import AddProjectModal from '../components/AddProjectModal';
import ProjectCard from '../components/ProjectCard';
import { AuthContext } from '../AuthContext';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const { user } = useContext(AuthContext);
  const addToast = useToast();

  useEffect(() => {
    const fetchProjects = async () => {
      const { data, error } = await supabase
        .from('projects')
        .select('id, name, description, is_favorite, project_group (id, name), tasks (id, name, description)');

      if (error) {
        console.error('Error fetching projects:', error);
        addToast('Error fetching projects: ' + error.message, 'danger');
      } else {
        // Sort projects: favorited first, then alphabetical
        const sortedData = data.sort((a, b) => {
          if (a.is_favorite === b.is_favorite) {
            return a.name.localeCompare(b.name);
          }
          return b.is_favorite - a.is_favorite;
        });
        setProjects(sortedData);
      }
    };

    fetchProjects();
  }, [addToast]);

  const handleProjectAdded = (newProject) => {
    setProjects((prev) => [...prev, newProject]);
  };

  const handleProjectUpdated = (updatedProject) => {
    setProjects((prev) =>
      prev.map((project) => (project.id === updatedProject.id ? updatedProject : project))
    );
  };

  const handleProjectDeleted = (deletedProjectId) => {
    setProjects((prev) => prev.filter((project) => project.id !== deletedProjectId));
  };

  const handleToggleFavorite = async (projectId, isFavorite) => {
      console.log("Re-sorting projects");
      setProjects((prev) =>
        prev
          .map((project) => (project.id === projectId ? { ...project, is_favorite: !isFavorite } : project))
          .sort((a, b) => {
            if (a.is_favorite === b.is_favorite) {
              return a.name.localeCompare(b.name);
            }
            return b.is_favorite - a.is_favorite;
          })
      );

  };

  return (
    <Container className="mt-5">
      <h1>Projects</h1>

      <Row className="mt-3">
        {projects.map((project) => (
          <Col key={project.id} sm={12} md={6} lg={4} className="mb-3">
            <ProjectCard
              project={project}
              onProjectUpdated={handleProjectUpdated}
              onProjectDeleted={handleProjectDeleted}
              onToggleFavorite={handleToggleFavorite}
            />
          </Col>
        ))}
      </Row>

      <Button variant="primary" onClick={() => setShowAddProjectModal(true)}>
        <FaPlus /> Add Project
      </Button>
      
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          handleClose={() => setShowAddProjectModal(false)}
          onProjectAdded={handleProjectAdded}
        />
      )}
    </Container>
  );
}

export default Projects;