import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { supabase } from '../supabaseClient';
import { useToast } from '../ToastContext';

function AddProjectGroupModal({ show, handleClose, userId, onProjectGroupAdded }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const addToast = useToast();

  const handleSave = async () => {
    console.log('Adding project group:', { name, description, userId });
    const { data, error } = await supabase
      .from('project_groups')
      .insert([{ name, description, owner: userId }])
      .select('name, description, owner, is_favorite, tags, color, projects (id)');

    console.log('Supabase response:', { data, error });

    if (error) {
      console.error('Error adding project group:', error);
      addToast('Error adding project group: ' + error.message, 'danger');
    } else if (data && data.length > 0) {
      addToast('Project group added successfully', 'success');
      onProjectGroupAdded(data[0]);
      handleClose();
    } else {
      console.error('Unexpected response format:', data);
      addToast('Unexpected response format when adding project group', 'danger');
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Project Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="description" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddProjectGroupModal;