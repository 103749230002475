import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle, FaTimesCircle } from 'react-icons/fa';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

const toastVariants = {
  success: { bg: 'bg-success text-white', icon: <FaCheckCircle /> },
  danger: { bg: 'bg-danger text-white', icon: <FaTimesCircle /> },
  warning: { bg: 'bg-warning text-dark', icon: <FaExclamationCircle /> },
  info: { bg: 'bg-info text-white', icon: <FaInfoCircle /> },
};

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((message, variant = 'info') => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), message, variant },
    ]);
  }, []);

  const removeToast = useCallback((id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <ToastContainer position="top-end" className="p-3">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            onClose={() => removeToast(toast.id)}
            className={`d-flex align-items-center ${toastVariants[toast.variant].bg}`}
            delay={3000}
            autohide
          >
            <Toast.Header closeButton={false}>
              <div className="me-2">{toastVariants[toast.variant].icon}</div>
              <strong className="me-auto">{toast.variant.charAt(0).toUpperCase() + toast.variant.slice(1)}</strong>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => removeToast(toast.id)}></button>
            </Toast.Header>
            <Toast.Body>{toast.message}</Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};